import React from "react"
import { graphql } from "gatsby"
import { Layout } from "@bw/layouts"
import { Seo, Button, Address } from "@bw/bits"
import { useTranslation } from "react-i18next"
import * as modules from "@bw/modules/resolvers"

const PoiTemplate = ({ data, pageContext }) => {
  const { t } = useTranslation()
  const { page, mount } = data

  const breadcrumbsLinks = [
    {
      title: t("homepage"),
      to: "/",
    },
  ]

  if (mount) {
    breadcrumbsLinks.push({
      title: mount.title,
      to: mount.slug,
    })
  }

  breadcrumbsLinks.push({
    title: page.title,
    to: page.slug,
  })

  /*
   * Loading the Contents modules, ex Content or CallToAction, TeasersLatest
   */
  const contents = page.content
    .filter(module => module !== null)
    .map((module, i) => {
      const LoadedModule = modules[module.__typename.replace("Module", "")]
      if (typeof LoadedModule === "undefined") {
        return null
      }

      if (module.buttonType?.value === "url" && module.buttonUrl) {
        module.button = (
          <Button
            label={module.buttonText}
            href={module.buttonUrl}
            external={module.buttonIsExternal}
            primary
          />
        )
      }

      if (module.buttonType?.value === "entry" && module.buttonEntry) {
        module.button = (
          <Button
            label={module.buttonText}
            to={module.buttonEntry.url}
            primary
          />
        )
      }

      module.buttonPosition = module.buttonPosition?.value

      return <LoadedModule key={i} {...module} />
    })

  return (
    <Layout {...{ pageContext }}>
      <Seo
        title={page.metaTitle || page.title}
        description={page.metaDescription}
        meta={[
          {
            name: "robots",
            content: page.doNotIndex
              ? "noindex, nofollow"
              : "max-image-preview:large, index, follow",
          },
        ]}
      />
      {/*
       * Loading the Header module, ex Hero or poiHeader
       */}
      {page.header
        .filter(module => module !== null)
        .map((module, i) => {
          const LoadedModule = modules[module.__typename.replace("Module", "")]
          if (typeof LoadedModule === "undefined") {
            return null
          }

          if (!module.title) {
            module.title = page.title
          }

          if (module.__typename === "PageHeaderModule") {
            module.breadcrumbs = breadcrumbsLinks
          }

          return <LoadedModule key={i} {...module} />
        })}

      <Address
        {...{
          street: page.street,
          city: page.city,
          zipCode: page.zipCode,
          state: page.state,
          country: page.country,
          phone: page.phone,
          email: page.email,
        }}
      />
      {contents}
    </Layout>
  )
}

export default PoiTemplate

export const poiQuery = graphql`
  query poiQuery($id: String!) {
    page: collectionPois(id: { eq: $id }) {
      title
      metaTitle
      metaDescription
      image {
        url
      }
      doNotIndex

      teaserImage {
        url
      }

      street
      city
      zipCode
      state
      country
      phone
      email
      latitude
      longitude
      openingHours {
        days {
          value
        }
        periods {
          from
          to
        }
      }

      header {
        __typename
        suptitle
        title
        subtitle

        ... on HeroModule {
          ...HeroModuleFragment
        }
      }
      content {
        __typename
        suptitle
        title
        subtitle
        backgroundVariant
        buttonType {
          value
        }
        buttonUrl
        buttonIsExternal
        buttonText
        buttonPosition {
          value
        }
        buttonEntry {
          url
        }

        ... on CallToActionModule {
          ...CallToActionModuleFragment
        }
        ... on ContentModule {
          ...ContentModuleFragment
        }
        ... on ContentWithImageModule {
          ...ContentWithImageModuleFragment
        }
        ... on GalleryModule {
          ...GalleryModuleFragment
        }
        ... on ContentOnColumnsModule {
          ...ContentOnColumnsModuleFragment
        }
        ... on DownloadsModule {
          ...DownloadsModuleFragment
        }
        ... on TeasersLatestModule {
          ...TeasersLatestModuleFragment
        }
        ... on TeasersCustomModule {
          ...TeasersCustomModuleFragment
        }
        ... on TeasersManualModule {
          ...TeasersManualModuleFragment
        }
        ... on LogoGridModule {
          ...LogoGridModuleFragment
        }
        ... on ImageModule {
          ...ImageModuleFragment
        }
        ... on ImagesLinksModule {
          ...ImagesLinksModuleFragment
        }
        ... on VideoModule {
          ...VideoModuleFragment
        }
        ... on FormModule {
          ...FormModuleFragment
        }
      }
    }
    mount: collectionPages(mount: { handle: { eq: "pois" } }) {
      slug
      title
    }
  }
`
