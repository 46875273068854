import React from "react"
import PageTemplate from "../../../templates/pageTemplate.jsx"
import ArticleTemplate from "../../../templates/articleTemplate.jsx"
import VideoTemplate from "../../../templates/videoTemplate.jsx"
import EventTemplate from "../../../templates/eventTemplate.jsx"
import FaqTemplate from "../../../templates/faqTemplate.jsx"
import PoiTemplate from "../../../templates/poiTemplate.jsx"
import PrefectureTemplate from "../../../templates/prefectureTemplate.jsx"
import TopicTemplate from "../../../templates/topicTemplate.jsx"

import { useQuery } from "react-query"
import axios from "axios"

const PagePreviewTemplate = ({ params, location }) => {
  const { isLoading, isError, data, error } = useQuery(
    location.search,
    async () => {
      const response = await axios.get(
        `/api/collections/${params.type}/entries/${params.id}${location.search}`,
        {
          headers: {
            "X-Statamic-Live-Preview": true,
          },
        }
      )
      const data = {
        ...response.data.data,
        header: response.data.data?.header
          ? response.data.data.header.map(content => ({
              ...content,
              __typename:
                content.type.charAt(0).toUpperCase() + content.type.slice(1),
            }))
          : null,
        content: Array.isArray(response.data.data?.content)
          ? response.data.data.content.map(content => ({
              ...content,
              __typename:
                content.type.charAt(0).toUpperCase() + content.type.slice(1),
            }))
          : response.data.data?.content,
      }

      return {
        data: {
          page: data,
        },
        pageContext: {
          locale: data.locale,
          translations: [],
        },
      }
    }
  )

  if (isLoading) {
    return "...chargement"
  }

  if (isError) {
    console.error(error)
    return "erreur de chargement"
  }

  switch (params.type) {
    case "articles":
      return <ArticleTemplate data={data.data} pageContext={data.pageContext} />
    case "pages":
      return <PageTemplate data={data.data} pageContext={data.pageContext} />
    case "videos":
      return <VideoTemplate data={data.data} pageContext={data.pageContext} />
    case "faqs":
      return <FaqTemplate data={data.data} pageContext={data.pageContext} />
    case "events":
      return <EventTemplate data={data.data} pageContext={data.pageContext} />
    case "pois":
      return <PoiTemplate data={data.data} pageContext={data.pageContext} />
    case "prefectures":
      return (
        <PrefectureTemplate data={data.data} pageContext={data.pageContext} />
      )
    case "topics":
      return <TopicTemplate data={data.data} pageContext={data.pageContext} />
    default:
      return "Il n'y a pas de prévisualition pour cette collection."
  }
}

export default PagePreviewTemplate
