import { graphql } from "gatsby"
import React from "react"
import { Layout } from "@bw/layouts"
import { Seo, Section } from "@bw/bits"
import { PageHeader, Content } from "@bw/modules"
// parse is used to ensure that HTML sent by the CMS is converted to React components
import parse from "html-react-parser"

const FaqTemplate = ({ data, pageContext }) => {
  const { page } = data

  if (typeof page === "undefined") {
    return null
  }

  return (
    <Layout {...{ pageContext }}>
      <Seo
        title={page.metaTitle || page.title}
        description={page.metaDescription}
        meta={[
          {
            name: "robots",
            content: page.doNotIndex
              ? "noindex, nofollow"
              : "max-image-preview:large, index, follow",
          },
        ]}
      />
      <PageHeader title={page.title} />
      <Section>
        <Content>{parse(page.content)}</Content>
      </Section>
    </Layout>
  )
}

export default FaqTemplate

export const faqQuery = graphql`
  query faqQuery($id: String!) {
    page: collectionFaqs(id: { eq: $id }) {
      title
      metaTitle
      metaDescription
      image {
        url
      }
      doNotIndex

      teaserImage {
        url
      }

      content
    }
  }
`
