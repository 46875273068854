import { graphql } from "gatsby"
import React from "react"
import { Layout } from "@bw/layouts"
import { PageHeader } from "@bw/modules"
import { Seo, Button, Avatar, CmsImage } from "@bw/bits"
import * as modules from "@bw/modules/resolvers"
import { useTranslation } from "react-i18next"
import dateFormat from "../utils/dateFormat"

const ArticleTemplate = ({ data, pageContext }) => {
  const { t } = useTranslation()
  const { page, mount } = data

  const breadcrumbsLinks = [
    {
      title: t("homepage"),
      to: "/",
    },
  ]

  if (mount) {
    breadcrumbsLinks.push({
      title: mount.title,
      to: mount.slug,
    })
  }

  breadcrumbsLinks.push({
    title: page.title,
    to: page.url,
  })

  return (
    <Layout {...{ pageContext }}>
      <Seo
        title={page.metaTitle || page.title}
        description={page.metaDescription}
        meta={[
          {
            name: "robots",
            content: page.doNotIndex
              ? "noindex, nofollow"
              : "max-image-preview:large, index, follow",
          },
        ]}
      />
      <PageHeader
        suptitle={
          page.date ? dateFormat(page.date, pageContext.locale) : undefined
        }
        title={page.title}
        breadcrumbs={breadcrumbsLinks}
        subtitle={page.authors.map((author, i) => (
          <Avatar
            key={i}
            image={
              author.avatar?.url ? (
                <CmsImage src={author.avatar.url} alt="" />
              ) : undefined
            }
            name={author.name}
          />
        ))}
      />
      {page.content
        .filter(module => module !== null)
        .map((module, i) => {
          const LoadedModule = modules[module.__typename.replace("Module", "")]
          if (typeof LoadedModule === "undefined") {
            return null
          }

          if (
            module.buttonType?.value === "url" &&
            module.buttonText &&
            module.buttonUrl
          ) {
            module.button = (
              <Button
                label={module.buttonText}
                href={module.buttonUrl}
                external={module.buttonIsExternal}
              />
            )
          }

          if (
            module.buttonType?.value === "entry" &&
            module.buttonText &&
            module.buttonEntry
          ) {
            module.button = (
              <Button label={module.buttonText} to={module.buttonEntry.url} />
            )
          }

          module.buttonPosition = module.buttonPosition?.value

          return <LoadedModule key={i} {...module} />
        })}
    </Layout>
  )
}

export default ArticleTemplate

export const articleQuery = graphql`
  query articleQuery($id: String!) {
    page: collectionArticles(id: { eq: $id }) {
      url
      title
      metaTitle
      metaDescription
      date
      locale
      image {
        url
      }
      doNotIndex
      teaserExcerpt
      teaserImage {
        url
      }
      authors {
        name
        avatar {
          url
        }
      }
      content {
        __typename
        suptitle
        title
        subtitle
        backgroundVariant
        buttonType {
          value
        }
        buttonUrl
        buttonIsExternal
        buttonText
        buttonPosition {
          value
        }
        buttonEntry {
          url
        }

        ... on CallToActionModule {
          ...CallToActionModuleFragment
        }
        ... on ContentModule {
          ...ContentModuleFragment
        }
        ... on ContentWithImageModule {
          ...ContentWithImageModuleFragment
        }
        ... on DownloadsModule {
          ...DownloadsModuleFragment
        }
        ... on GalleryModule {
          ...GalleryModuleFragment
        }
        ... on ContentOnColumnsModule {
          ...ContentOnColumnsModuleFragment
        }
        ... on TeasersLatestModule {
          ...TeasersLatestModuleFragment
        }
        ... on LogoGridModule {
          ...LogoGridModuleFragment
        }
        ... on ImageModule {
          ...ImageModuleFragment
        }
        ... on ImagesLinksModule {
          ...ImagesLinksModuleFragment
        }
        ... on VideoModule {
          ...VideoModuleFragment
        }
      }
    }

    mount: collectionPages(mount: { handle: { eq: "articles" } }) {
      slug
      title
    }
  }
`
